import * as React from 'react';
import CustomerForm from '@pages/customers/form';
import CompanyForm from '@pages/company/form';
import { setModalContent } from '@components/modal';
import { translate } from '@data/translations';
import { CompanyModel, CustomerModel } from '@common/modelDefinition';
import { Reservation /* ReservationController */ } from './resController';
import { searchCustomer, searchCompany } from './api';
import { ReactComponent as Edit } from '../../assets/custom/icons/edit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/custom/icons/trash.svg';
import { ReactComponent as NewIcon } from '../../assets/custom/icons/plus.svg';

var searchDebouncer: NodeJS.Timeout = setTimeout(() => {}, 0);

interface CustomersAndCompaniesInterface {
    customer: CustomerModel | null;
    company: CompanyModel | null;
    history: any;
    customerSelect: (customer: CustomerModel) => void;
    companySelect: (company: CompanyModel) => void;
    docScanner?: boolean;
    showCompany?: boolean;
    //className?: string;
    customerOrCompanyInvalid?: boolean;
    deleteCompany?: (company: CompanyModel) => void;
    deleteCustomer?: (customer: CustomerModel) => void;
    reservation?: Reservation;
    editCustomer?: (customer: CustomerModel) => void;
    editCompany?: (company: CompanyModel) => void;
    doNotShowCustomerSearch?: boolean;
    flexColumn?: boolean;
}

const CustomersAndCompanies = (props: CustomersAndCompaniesInterface) => {
    const { customerSelect, companySelect, docScanner = false } = props;
    const {
        history,
        company,
        // className,
        customer,
        customerOrCompanyInvalid = false,
        showCompany = true,
        reservation = null,
        flexColumn,
    } = props;

    // const ctrl = new ReservationController();

    const [customers, setCustomers] = React.useState<CustomerModel[]>([]);
    const [companies, setCompanies] = React.useState<CompanyModel[]>([]);
    const [customerSearch, setCustomerSearch] = React.useState('');
    const [companySearch, setCompanySearch] = React.useState('');

    const onCustomerSearch = (e: any) => {
        clearTimeout(searchDebouncer);
        const searchString = e.target.value;
        setCustomerSearch(searchString);
        searchDebouncer = setTimeout(async () => {
            const result = await searchCustomer(searchString);
            setCustomers(result);
        }, 500);
    };

    const onCompanySearch = (e: any) => {
        clearTimeout(searchDebouncer);
        const searchString = e.target.value;
        setCompanySearch(searchString);
        searchDebouncer = setTimeout(async () => {
            const result = await searchCompany(searchString);
            setCompanies(result);
        }, 500);
    };

    const onSelectCompany = (company: CompanyModel) => {
        companySelect(company);
        setCompanies([]);
        setCompanySearch('');
    };

    const onSelectCustomer = (customer: CustomerModel) => {
        customerSelect(customer);
        setCustomers([]);
        setCustomerSearch('');
    };

    const addCompany = () => {
        setModalContent(
            <CompanyForm
                history={history}
                callback={(company: CompanyModel) => {
                    companySelect(company);
                    setCompanies([]);
                    setCompanySearch('');
                }}
                customHeader={' '}
                cancelHandler={() => {}}
                showPageTitle={false}
            />,
            translate('Company'),
            false,
            'modal-md'
        );
    };

    const addCustomer = () => {
        setModalContent(
            <CustomerForm
                history={history}
                callback={(customer: CustomerModel) => {
                    customerSelect(customer);
                    setCustomerSearch('');
                    setCustomers([]);
                }}
                customHeader={' '}
                cancelHandler={() => {}}
                showPageTitle={false}
                reservation={reservation}
            />,
            translate('Guest'),
            false,
            'modal-xl'
        );
    };

    const comapniesSearchResult = companies.map((c: CompanyModel, index: number) => {
        const name = (
            <div className="display-flex" style={{ overflow: 'hidden', width: '100%' }}>
                <i className="fa fa-user mr-2p px-2" aria-hidden="true"></i>
                {c.name}
            </div>
        );
        const town = (
            <div className="display-flex" style={{ overflow: 'hidden', width: '100%' }}>
                <i className="fa fa-building mr-2p" aria-hidden="true"></i> {c.address}
                {c.address && c.town ? ', ' : ''} {c.town}
            </div>
        );
        const web = (
            <div className="display-flex" style={{ overflow: 'hidden', width: '100%' }}>
                <i className="fa fa-globe mr-2p" aria-hidden="true"></i>
                {c.web}
            </div>
        );

        return (
            <div
                key={'c' + c.id + index}
                className="custom-list-item"
                style={{ marginLeft: 0, marginRight: 0 }}
                onClick={onSelectCompany.bind(this, c)}
            >
                {name}
                {town}
                {web}
            </div>
        );
    });

    const customersSearchResult = customers.map((c: CustomerModel, index: number) => {
        const doc = (
            <div className="display-flex text-bold" style={{ overflow: 'hidden', width: '100%' }}>
                <i className="fa fa-id-card-o mr-2p px-1" />
                {c.documentId}
            </div>
        );
        const name = (
            <div className="display-flex" style={{ overflow: 'hidden', width: '100%' }}>
                <i className="fa fa-user mr-2p px-2" aria-hidden="true"></i>
                {c.firstName} {c.lastName}
            </div>
        );
        // const phone = (
        //     <div className="display-flex" style={{ overflow: 'hidden', width: '100%' }}>
        //         <i className="fa fa-phone mr-2p ml-2p " aria-hidden="true"></i>
        //         {c.phoneOrMobile}
        //     </div>
        // );
        const email = (
            <div className="display-flex " style={{ overflow: 'hidden', width: '100%' }}>
                <i className="fa fa-envelope-o mr-2p ml-2p " aria-hidden="true"></i> {c.email}
            </div>
        );

        return (
            <div
                key={'c' + c.id + index}
                className="custom-list-item custom-list-item-last-child-text"
                style={{ marginLeft: 0, marginRight: 0, overflowX: 'auto' }}
                onClick={onSelectCustomer.bind(this, c)}
            >
                {doc}
                {name}
                {email}
                {/* {phone} */}
            </div>
        );
    });

    let inputFieldCss = ' input input-modal ';

    if (showCompany && customerOrCompanyInvalid) {
        inputFieldCss = inputFieldCss + ' is-invalid';
    }

    return (
        <div className="w-100">
            <div className={'display-flex align-items-center ' + (flexColumn ? ' flex-column ' : '')}>
                {props.doNotShowCustomerSearch ? null : (
                    <div className="flex">
                        {customer ? (
                            <div className="display-flex align-items-center">
                                <div
                                    className="mr-4p ml-4p"
                                    style={{ fontSize: '14px', fontWeight: 'bold', color: '#1068ec' }}
                                >{`${customer.firstName} ${customer.lastName}`}</div>
                                {props.editCustomer ? (
                                    <div
                                        className="button-white-w-icon-only"
                                        onClick={() => {
                                            setModalContent(
                                                <CustomerForm
                                                    callback={(updatedCustomer: CustomerModel) => {
                                                        if (props.editCustomer) {
                                                            props.editCustomer(updatedCustomer);
                                                        }
                                                    }}
                                                    customerId={customer.id}
                                                    reservation={reservation}
                                                />,
                                                translate('Edit customer'),
                                                false,
                                                'modal-xl'
                                            );
                                        }}
                                    >
                                        <Edit />
                                    </div>
                                ) : null}
                                {props.deleteCustomer ? (
                                    <div
                                        className="button-white-w-icon-only fill-red"
                                        onClick={() => {
                                            if (props.deleteCustomer) {
                                                props.deleteCustomer(customer);
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <div className="display-flex align-items-center p-1">
                                <div className={docScanner ? ' flex ' : ' flex '}>
                                    <input
                                        value={customerSearch}
                                        onChange={onCustomerSearch}
                                        className={inputFieldCss}
                                        type="text"
                                        placeholder={translate('SEARCH CUSTOMERS')}
                                    />
                                </div>
                                <div className={''}>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            addCustomer();
                                        }}
                                        className={'button-white-w-icon-only fill-primary'}
                                    >
                                        <NewIcon />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {showCompany ? (
                    <div className="flex">
                        {company ? (
                            <div className="flex-center ">
                                <div className="ml-4p mr-4p flex-center flex-wrap" style={{ color: '#1068ec' }}>
                                    <div
                                        className=""
                                        style={{ fontSize: '14px', fontWeight: 'bold', color: '#1068ec' }}
                                    >
                                        {company.name}{' '}
                                    </div>{' '}
                                    {flexColumn ? (
                                        <div className="flex-center ml-8p" style={{ fontSize: '12px' }}>
                                            {company.address} {company.town}
                                        </div>
                                    ) : null}
                                </div>
                                {props.editCompany ? (
                                    <div
                                        className="button-white-w-icon-only"
                                        onClick={() => {
                                            setModalContent(
                                                <CompanyForm
                                                    callback={(updatedCompany: CompanyModel) => {
                                                        if (props.editCompany) {
                                                            props.editCompany(updatedCompany);
                                                        }
                                                    }}
                                                    companyId={company.id}
                                                />,
                                                translate('Edit company'),
                                                false,
                                                'modal-xl'
                                            );
                                        }}
                                    >
                                        <Edit />
                                    </div>
                                ) : null}
                                {props.deleteCompany ? (
                                    <div
                                        className="button-white-w-icon-only fill-red"
                                        onClick={() => {
                                            if (props.deleteCompany) {
                                                props.deleteCompany(company);
                                            }
                                        }}
                                    >
                                        <DeleteIcon />
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <div className="display-flex align-items-center p-1">
                                <div className="flex">
                                    <input
                                        value={companySearch}
                                        onChange={onCompanySearch}
                                        className={inputFieldCss}
                                        type="text"
                                        placeholder={translate('SEARCH COMPANIES')}
                                    />
                                </div>
                                <div className="">
                                    <div onClick={addCompany} className={'button-white-w-icon-only fill-primary'}>
                                        <NewIcon />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}
            </div>

            {(showCompany && comapniesSearchResult.length > 0) || customersSearchResult.length > 0 ? (
                <div className="customers-and-company-search-result shadow-sm">
                    {showCompany ? comapniesSearchResult : null}
                    {customersSearchResult}
                </div>
            ) : null}
        </div>
    );
};

export { CustomersAndCompanies };
