import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { translate } from '@data/translations';
import { ReservationController, Reservation } from '../reservations2/resController';
import { setModalContent } from '@components/modal';
import ShowAvailableRoomsForReservationModal from '../reservations2/ChangeRoomModal';
import { RoomInfoModel, AccommodationPricelistModel, CustomerModel, CompanyModel } from '@common/modelDefinition';
import { condoStatusCheck } from '../reservations2/utils';
import { reservationEnums } from '@data/reservationStatusEnums';
import { ReactComponent as Clock } from '../../assets/custom/icons/clock.svg';
import { ReactComponent as ValidImg } from '../../assets/custom/icons/tick_inside_circle.svg';
import { ReactComponent as Warning } from '../../assets/custom/icons/warning.svg';
import { ReactComponent as Plus } from '../../assets/custom/icons/plus.svg';
import TabsBody from './tabsBody';
import { CustomersAndCompanies } from '@pages/reservations2/CustomersAndCompanies';

export const ActiveIcon = () => <Clock />;
export const ValidIcon = () => <ValidImg />;
export const InvalidIcon = () => <Warning />;
const ctrl = new ReservationController();

interface TabsWrapperProps {
    history: any;
    activeReservationIndex: number;
    roomInfoMap: any;
    acp: AccommodationPricelistModel[];
    showReservationDetails?: Function;
}

function TabsWrapper(props: TabsWrapperProps) {
    const { roomInfoMap, history, activeReservationIndex, acp, showReservationDetails } = props;

    const tabIndex: number = activeReservationIndex;
    const reservations = ctrl.getReservations();
    const groupReservation = ctrl.getGroupReservation();
    const [loading, setLoading] = React.useState(false);

    function validateReservation(r: Reservation, index: number) {
        const { isFullyValid } = ctrl.getReservationValidationReport(r);
        if (!isFullyValid) {
            return <InvalidIcon />;
        } else if (index === tabIndex) {
            return <ActiveIcon />;
        } else {
            return <ValidIcon />;
        }
    }

    const addRoom = () => {
        setModalContent(
            <ShowAvailableRoomsForReservationModal
                reservation={{ ...reservations[0] }}
                changeRoomHandler={(room: RoomInfoModel) => ctrl.createNewRes(room, acp)}
            />,
            translate('Add room to reservation'),
            false,
            'modal-lg'
        );
    };

    const groupRes = ctrl.getGroupReservation();
    const groupReservationHolder = ctrl.getGrupReservationHolder();

    const setGroupHolderToAllRes = (type: string, customer: CustomerModel | null, company: CompanyModel | null) => {
        if (reservations?.length > 1) {
            const everyResNotCreated = reservations.every((r: Reservation) => !r.id);
            for (let i = 0; i < reservations.length; i++) {
                const res = { ...reservations[i] };
                if (res && everyResNotCreated && res.customerId === null && res.companyId === null) {
                    ctrl.setActiveReservationIndex(i);
                    if (type === 'customer') {
                        res.Customer = customer;
                        res.customerId = customer?.id ? customer.id : null;
                        ctrl.updateActiveReservation(res as Reservation);
                    } else if (type === 'company') {
                        res.Company = company;
                        res.companyId = company?.id ? company.id : null;
                        ctrl.updateActiveReservation(res as Reservation);
                    }
                }
            }
        }
    };

    const { isCondoReservation } = condoStatusCheck(reservations[tabIndex]);
    const isAccommodationInvoiceIssued: boolean = reservations[tabIndex]?.genuineInvoiceIssued === 1 ? true : false;

    return (
        <div className="reservation-form-main-wrapper">
            {groupRes?.uuid ? (
                <div className="" style={{ width: '100%' }}>
                    <div className="mb-4p mt-2p" style={{ width: '100%' }}>
                        <div className="text-bold mb-4p mt-4p">{translate('Group reservation holder')}</div>
                        <CustomersAndCompanies
                            history={history}
                            company={groupReservationHolder.company}
                            customer={groupReservationHolder.customer}
                            customerSelect={(c: CustomerModel) => {
                                groupReservationHolder.customer = c;
                                ctrl.setGroupReservationHolder(groupReservationHolder);
                                setGroupHolderToAllRes('customer', c, null);
                            }}
                            companySelect={(c: CompanyModel) => {
                                groupReservationHolder.company = c;
                                ctrl.setGroupReservationHolder(groupReservationHolder);
                                setGroupHolderToAllRes('company', null, c);
                            }}
                            deleteCustomer={(c: CustomerModel) => {
                                groupReservationHolder.customer = null;
                                ctrl.setGroupReservationHolder(groupReservationHolder);
                                setGroupHolderToAllRes('customer', null, null);
                            }}
                            deleteCompany={(c: CompanyModel) => {
                                groupReservationHolder.company = null;
                                ctrl.setGroupReservationHolder(groupReservationHolder);
                                setGroupHolderToAllRes('company', null, null);
                            }}
                            editCustomer={(c: CustomerModel) => {
                                groupReservationHolder.customer = c;
                                ctrl.setGroupReservationHolder(groupReservationHolder);
                                setGroupHolderToAllRes('customer', c, null);
                            }}
                            editCompany={(c: CompanyModel) => {
                                groupReservationHolder.company = c;
                                ctrl.setGroupReservationHolder(groupReservationHolder);
                                setGroupHolderToAllRes('company', null, c);
                            }}
                        />
                    </div>
                    <div className="display-flex mobile-page-form">
                        <div className="flex mt-4p mb-4p mr-4p">
                            <div className="text-bold ml-4p mb-4p">{translate('Group reservation name')}:</div>
                            <input
                                onChange={(e) => ctrl.setGroupName(e.target.value)}
                                placeholder={groupRes?.groupName}
                                value={groupRes?.groupName}
                                type="text"
                                className="input input-modal"
                            />
                        </div>
                        <div className="flex mt-4p mb-4p">
                            <div className="text-bold ml-4p mb-4p">{translate('Note')}:</div>
                            <textarea
                                rows={1}
                                className="input input-modal"
                                placeholder={translate('Group reservation note')}
                                name="note"
                                onChange={(e) => ctrl.setNote(e.target.value)}
                                value={groupRes?.note ? groupRes?.note : ''}
                            ></textarea>
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="reservation-tabs-wrapper">
                {reservations.map((r, index) => {
                    const { isFullyValid } = ctrl.getReservationValidationReport(r);
                    const room = r.roomInfoId ? roomInfoMap[r.roomInfoId] : null;
                    if (!room) {
                        return (
                            <div
                                onClick={async () => {
                                    setLoading(true);
                                    await ctrl.setActiveReservationsModels(index);
                                    ctrl.setActiveReservationIndex(index);
                                    setLoading(false);
                                }}
                                key={index}
                                className="reservation-tabs-single"
                                style={{ order: index }}
                            >
                                <div
                                    className={
                                        reservations.length > tabIndex
                                            ? index === tabIndex
                                                ? 'active'
                                                : isFullyValid
                                                ? ' valid-res-form '
                                                : ' invalid-res-form '
                                            : index === tabIndex - 1
                                            ? 'active'
                                            : isFullyValid
                                            ? ' valid-res-form '
                                            : ' invalid-res-form '
                                    }
                                >
                                    <InvalidIcon />
                                </div>
                            </div>
                        );
                    }

                    const removedFromGroup = groupReservation && groupReservation.uuid !== r.groupUuid;
                    return (
                        <div
                            onClick={async () => {
                                setLoading(true);
                                await ctrl.setActiveReservationsModels(index);
                                setLoading(false);
                                ctrl.setActiveReservationIndex(index);
                            }}
                            key={index}
                            className="reservation-tabs-single"
                        >
                            <div
                                className={
                                    reservations.length > tabIndex
                                        ? index === tabIndex
                                            ? ' active '
                                            : isFullyValid
                                            ? ' valid-res-form '
                                            : ' invalid-res-form '
                                        : index === tabIndex - 1
                                        ? ' active '
                                        : isFullyValid
                                        ? ' valid-res-form '
                                        : ' invalid-res-form '
                                }
                            >
                                {validateReservation(r, index)}
                                {room.name} ({r.adultsNumber},{r.childrenNumber})
                                {removedFromGroup ? (
                                    <i
                                        className="fa fa-toggle-up text-danger ml-2"
                                        title={translate('Removed from group')}
                                    />
                                ) : null}
                                {r.statusEnum === reservationEnums.canceled ? (
                                    <i className="fa fa-toggle-up text-warning ml-2" title={translate('Canceled')} />
                                ) : null}
                            </div>
                        </div>
                    );
                })}

                {!isCondoReservation ? (
                    <div
                        style={isAccommodationInvoiceIssued ? { pointerEvents: 'none' } : {}}
                        onClick={() => {
                            if (!isAccommodationInvoiceIssued) {
                                addRoom();
                            } else {
                                return null;
                            }
                        }}
                        className="reservation-tabs-single fill-primary"
                    >
                        <Plus />
                    </div>
                ) : null}
            </div>

            <TabsBody
                history={history}
                loading={loading}
                setLoading={setLoading}
                showReservationDetails={showReservationDetails}
            />
        </div>
    );
}

export default branch(
    {
        roomInfoMap: ['map', 'RoomInfo'],
        activeReservationIndex: ['state', 'reservationForm2', 'activeReservationIndex'],
        acp: ['model', 'AccommodationPriceList'],
    },
    TabsWrapper
);
